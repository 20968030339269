import Footer from "@/components/footer";
import Header from "@/components/header";
import { loadCSSAssets } from "@/utility/loadAssets";
import { CircularProgress } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";

const MainLayout = () => {
  const location = useLocation();
  const [hasChange, setHasChange] = useState(false);
  const [isTop, setIsTop] = useState(true);
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const [loading, setloading] = useState(true);
  const adminSecret = import.meta.env.VITE_ADMIN_LOGIN_SECRET;
  const isAdmin = useMemo(
    () =>
      location.pathname.includes(adminSecret) ||
      location.pathname.includes("cms-ybrs"),
    [location.pathname]
  );

  useEffect(() => {
    const handleScroll = () => setIsTop(window.scrollY === 0);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    setHasChange(true);
  }, [location.pathname]);
  useEffect(() => {
    if (hasChange) {
      scrollToTop();
      setHasChange(false);
    }
  }, [hasChange]);
  useEffect(() => {
    setloading(true);
    const neededLinks = ["base.css"];
    loadCSSAssets(isAdmin, neededLinks);
    setTimeout(() => {
      setloading(false);
    }, 1000);
  }, [isAdmin]);
  if (loading) {
    return (
      <div className=" ybrs-flex ybrs-items-center ybrs-justify-center ybrs-h-screen ybrs-w-full">
        <CircularProgress />
      </div>
    );
  }
  return (
    <>
      <div id="#top" className="boxed_wrapper ltr">
        {!isAdmin && <Header />}
        <Outlet />
        {!isAdmin && <Footer />}
      </div>
      {!isAdmin && (
        <a
          className={`scroll-to-top scroll-to-target d-block ybrs-transition-opacity ${
            isTop ? "ybrs-opacity-0" : "ybrs-opacity-1"
          }`}
          onClick={scrollToTop}
        >
          <i className="fas fa-angle-up"></i>
        </a>
      )}
    </>
  );
};

export default MainLayout;
