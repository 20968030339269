import { ColumnType } from "@/components/table/types/ColumnModel";
import { PostQuery } from "@/types/PostQuery";
import { useNavigate } from "react-router-dom";
import ContactUsAction from "../../components/ContactUsAction";
import ContactUsService from "../../services/ContactUsService";
import { ContactUsModel } from "../../types/ContactUsModel";
import moment from "moment";
import { useState } from "react";

const useListContactUs = () => {
  const uniqKey = "contactus";
  const contactusService = new ContactUsService();
  const navigate = useNavigate();
  const [defaultQuery, _] = useState<PostQuery>({
    keywords: "",
    filters: [],
    sorts: [{ key: "createdDate", order: "DESC" }],
    page: 1,
    size: 5,
  });
  const columns: ColumnType<ContactUsModel>[] = [
    {
      field: "firstName",
      headerName: "Nama Depan",
      type: "string",
    },
    {
      field: "lastName",
      headerName: "Nama Belakang",
      type: "string",
    },
    {
      field: "email",
      headerName: "Email",
      type: "string",
    },
    {
      field: "phone",
      headerName: "No. Telp",
      type: "string",
    },
    {
      field: "subject",
      headerName: "Subjek",
      type: "string",
    },
    {
      field: "pesan",
      headerName: "Pesan",
      type: "string",
    },
    {
      field: "createdDate",
      headerName: "Tanggal Dibuat", 
      type: "date",
      renderCell: (data: ContactUsModel) => {
        return <>{moment(data.createdDate).format("DD/MM/YYYY")}</>;
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      renderCell: (data: ContactUsModel) => {
        return <ContactUsAction data={data} />;
      },
    },
  ];
  const handleAddButtonClick = () => {
    navigate("/cms-ybrs/contact-us/tambah");
  };

  const service = (postQuery: PostQuery) => {
    return contactusService.all(postQuery);
  };
  return {
    uniqKey,
    columns,
    handleAddButtonClick,
    service,
    defaultQuery,
  };
};

export default useListContactUs;
