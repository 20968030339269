function loadCSSAssets(isAdmin: boolean, assets: string[]) {
  if (!isAdmin) {
    const links = Array.from(
      document.head.querySelectorAll('link[rel="stylesheet"]')
    )
      .filter((link) =>
        assets.some((href) => (link as HTMLLinkElement).href.includes(href))
      )
      .reduce((obj, link) => {
        obj[
          (link as HTMLLinkElement)?.href?.match(
            /([^\/]+)\.css$/
          )?.[1] as string
        ] = true;
        return obj;
      }, {} as Record<string, boolean>);
    assets.forEach((link) => {
      if (!links[link]) {
        const el = document.createElement("link");
        el.rel = "stylesheet";
        el.href = `css/${link}`;
        document.head.appendChild(el);
      }
    });
  } else {
    document
      .querySelectorAll("link[rel='stylesheet']")
      .forEach((el) =>
        assets.some(
          (href) => (el as HTMLLinkElement).href.includes(href) && el.remove()
        )
      );
  }
}

export { loadCSSAssets };
