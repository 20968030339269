import { ColumnType } from "@/components/table/types/ColumnModel";
import { PersonModel } from "../../types/PersonModel";
import { useNavigate } from "react-router-dom";
import PersonService from "../../services/PersonService";
import { PostQuery } from "@/types/PostQuery";
import { useState } from "react";
import ImagePreview from "@/components/media/ImagePreview";
import PersonAction from "../../components/PersonAction";
import { useQuery } from "@tanstack/react-query";
import PeriodeService from "../../services/PeriodeService";

const useDetailPerson = (id: number, type: string) => {
  const uniqKey = `person_${type}`;
  const personService = new PersonService(type);
  const periodeService = new PeriodeService(type);

  const navigate = useNavigate();
  const [defaultPostQuery, _] = useState<PostQuery>({
    keywords: "",
    filters: [
      {
        key: "PeriodeId",
        operator: "EQUAL",
        values: [id.toString()],
      },
    ],
    sorts: [
      {
        key: "order",
        order: "ASC",
      },
    ],
    page: 0,
    size: 0,
  });
  const columns: ColumnType<PersonModel>[] = [
    {
      field: "order",
      type: "number",
      headerName: "Order",
    },
    {
      field: "name",
      type: "string",
      headerName: "Name",
    },
    {
      field: "imageFilePath",
      headerName: "Foto",
      hideFilter: true,
      hideSort: true,
      renderCell: (row: PersonModel) => (
        <ImagePreview
          imageStyle={{ width: "120px", height: "120px", objectFit: "cover" }}
          src={row.imageFilePath}
          alt="kegiatan"
        />
      ),
    },
    {
      field: "jabatan",
      type: "string",
      headerName: "Jabatan",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      renderCell: (data: PersonModel) => {
        return <PersonAction id={id} type={type} data={data} />;
      },
    },
  ];
  const handleAddButtonClick = () => {
    navigate(`/cms-ybrs/organ/${type}/person/${id}/tambah`);
  };
  const { data: periode } = useQuery({
    queryKey: ["periode-detail", id],
    queryFn: () => periodeService.getDetail(id),
  });
  const service = (postQuery: PostQuery) => {
    return personService.all(postQuery);
  };
  return {
    periode,
    navigate,
    uniqKey,
    columns,
    handleAddButtonClick,
    service,
    defaultPostQuery,
  };
};

export default useDetailPerson;
