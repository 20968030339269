import sk1 from "@/assets/images/satuankerja/rsimmanuel.jpg";
import sk2 from "@/assets/images/satuankerja/rsbayukarta.jpg";
import sk3 from "@/assets/images/satuankerja/rsridogalih.jpg";
import sk4 from "@/assets/images/satuankerja/kliniksilihasih.jpg";

export const satuanKerjaData = [
  {
    id: "rsimmanuel",
    imgSrc: sk1,
    title: "rsimmanuel",
    description:
      "rsimmanuel_description",
    link: "https://www.rsimmanuel.com/",
    route: "/satuan-kerja/rsimmanuel",
    visi: "visi_rsimmanuel",
    misi: [
      "misi_rsimmanuel_1",
      "misi_rsimmanuel_2",
      "misi_rsimmanuel_3",
      "misi_rsimmanuel_4",
      "misi_rsimmanuel_5",
    ],
    nilai: [
      "nilai_rsimmanuel_1",
      "nilai_rsimmanuel_2",
      "nilai_rsimmanuel_3",
      "nilai_rsimmanuel_4",
      "nilai_rsimmanuel_5",
      "nilai_rsimmanuel_6",
      "nilai_rsimmanuel_7",
      "nilai_rsimmanuel_8",
    ],
  },
  {
    id: "rsbayukarta",
    imgSrc: sk2,
    title: "rsbayukarta",
    description:
      "rsbayukarta_description",
    link: "https://www.rsbayukarta.net/",
    route: "/satuan-kerja/rsbayukarta",
    visi: "visi_rsbayukarta",
    misi: [
      "misi_rsbayukarta_1",
      "misi_rsbayukarta_2",
      "misi_rsbayukarta_3",
      "misi_rsbayukarta_4",
      "misi_rsbayukarta_5",
    ],
    nilai: [],
  },
  {
    id: "rsridogalih",
    imgSrc: sk3,
    title: "rsridogalih",
    description:
      "rsridogalih_description",
    link: "https://rsridogalih.com/",
    route: "/satuan-kerja/rsridogalih",
    visi: "visi_rsridogalih",
    misi: [
      "misi_rsridogalih_1",
      "misi_rsridogalih_2",
      "misi_rsridogalih_3",
    ],
    nilai: [
      "nilai_rsridogalih_1",
      "nilai_rsridogalih_2",
      "nilai_rsridogalih_3",
      "nilai_rsridogalih_4",
      "nilai_rsridogalih_5",
      "nilai_rsridogalih_6",
      "nilai_rsridogalih_7",
    ],
  },
  {
    id: "kliniksilihasih",
    imgSrc: sk4,
    title: "kliniksilihasih",
    description:
      "kliniksilihasih_description",
    link: "http://kliniksilihasih.com/",
    route: "/satuan-kerja/kliniksilihasih",
    visi: "visi_kliniksilihasih",
    misi: [
      "misi_kliniksilihasih_1",
      "misi_kliniksilihasih_2",
      "misi_kliniksilihasih_3",
      "misi_kliniksilihasih_4",
    ],
    nilai: [],
  },
];
