import shape1 from "@/assets/images/shape/shape-1.png";
import shape4 from "@/assets/images/shape/shape-4.png";
import shape5 from "@/assets/images/shape/shape-5.png";

import { Link } from "react-router-dom";
import useHero from "./useHero";

const HeroSection = () => {
  const { t, images, currentImageIndex } = useHero();

  return (
    <section className="banner-section p_relative">
      <div
        className="pattern-layer wow slideInDown animated"
        data-wow-delay="00ms"
        data-wow-duration="1500ms"
        style={{
          backgroundImage: `url(${shape1})`,
          visibility: "visible",
          animationDuration: "1500ms",
          animationDelay: "0ms",
        }}
      ></div>

      <div className="shape">
        {/* <div
          className="shape-1"
          style={{ backgroundImage: `url(${shape2})` }}
        ></div> */}
        {/* <div
          className="shape-2 float-bob-x"
          style={{ backgroundImage: `url(${shape2})` }}
        ></div> */}
        <div
          className="shape-3"
          style={{
            backgroundImage: `url(${shape4})`,
          }}
        ></div>
        <div
          className="shape-4"
          style={{ backgroundImage: `url(${shape5})` }}
        ></div>
      </div>
      <div className="auto-container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12 col-sm-12 content-column">
            <div className="content-box">
              <span className="upper-text">{t("selamat_datang")}</span>
              <h2 className="mb-2 mt-4">
                Yayasan
                <br />
                Badan Rumah Sakit
                <br />
                Gereja Kristen Pasundan
                {/* {t("karena")} <span className="ybrs-ml-4">{t("kasih")}</span>
                <br />
                {" "}{t("kami_melayani_dan_menjadi_berkat")}
                <br className="ybrs-block md:ybrs-hidden" />
                {" "}{t("bagi_sesama")}. */}
              </h2>
              <h5 className="!ybrs-text-secondary">{t("1949")}</h5>
              <h3 className="!ybrs-text-white mt-3 mb-4">{t("melayani")}</h3>
              <div className="btn-box ybrs-mt-4">
                <Link className="theme-btn btn-two" to="/tentang-kami">
                  <span>{t("tentang_kami")}</span>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 image-column">
            <div className="image-box">
              <figure className="image float-bob-y">
                {images.map(
                  (image, index) =>
                    currentImageIndex === index && (
                      <img
                        key={index}
                        className={`fade-image `}
                        style={{
                          width: "600px",
                          height: "600px",
                        }}
                        src={image}
                        alt=""
                      />
                    )
                )}
              </figure>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
